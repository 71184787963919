import Partners from "../sections/Partners";
import Services from "../sections/Services";
import Price from "../sections/Prices";
import {PaperAirplaneIcon} from "@heroicons/react/20/solid";
import WAIcon from "../assets/img/wa.svg";
import Modal from "../components/Modal";
import {useState} from "react";
import Form from "../components/Form";
import Main from "../sections/Main";
const Home = ()=>{



    return (
            <>
                <Main/>
                <Partners/>
                <Services/>
                <Price/>
            </>

    );
}
export default Home
