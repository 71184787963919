import { Cog8ToothIcon,ShieldCheckIcon,PencilSquareIcon, ChartPieIcon , ClockIcon ,CurrencyDollarIcon } from '@heroicons/react/24/outline'

const Services = () => {


    const features = [
        {
            name: 'Техническое сопровождение',
            description:
                'Мы обеспечим ваш сайт актуальными обновлениями, регулярными резервными копиями и мониторингом производительности. Таким образом, вы можете быть уверены, что ваш сайт всегда работает на высшем уровне.',
            icon: Cog8ToothIcon,

        },
        {
            name: 'Безопасность',
            description:
                'Защита вашего сайта от вредоносных атак и хакеров - наш приоритет. Мы регулярно обновляем системы безопасности и мониторим уязвимости, чтобы ваш сайт был защищен.',
            icon: ShieldCheckIcon,

        },
        {
            name: 'Поддержка контента',
            description:
                'Наша команда специалистов может обновлять и добавлять контент на ваш сайт, включая текст, изображения и видео. Мы также поможем вам с оптимизацией контента для поисковых систем.',
            icon: PencilSquareIcon,

        },
        {
            name: 'Аналитика и оптимизация',
            description:
                'Мы анализируем данные о посещаемости и поведении пользователей на вашем сайте, чтобы помочь вам оптимизировать его для лучшей конверсии и эффективности.',
            icon: ChartPieIcon,

        },
        {
            name: 'Круглосуточная поддержка',
            description:
                'Наши специалисты доступны 24/7 для решения любых проблем и ответов на ваши вопросы.',
            icon: ClockIcon,

        },
        {
            name: 'Мониторинг и анализ расходов',
            description:
                'Мы анализируем ваши счета за хостинг и доменные услуги, предостерегая вас от неожиданных расходов или недоразумений в счетах.',
            icon: CurrencyDollarIcon,

        },
    ]




    return (
        <div className="bg-white py-8 dark:bg-gray-900" id="services">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 relative isolate">

                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="mx-auto  text-center">

                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-300">Надежное сопровождение и поддержка вашего веб-проекта</p>
                    <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                        Для успешного онлайн-присутствия вашего бизнеса необходимо не только создать привлекательный и функциональный веб-сайт, но и обеспечить его надежное и бесперебойное функционирование. Именно в этом мы можем вам помочь.
                    </p>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-300 my-20">Наши услуги</h2>
                </div>
                <div className="mx-auto mt-12 max-w-2xl sm:mt-20 lg:mt-14 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">

                            {features.map((feature) => (
                                <div  className="relative pl-16" key={feature.name}>
                                <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-r from-indigo-500 to-indigo-600">
                                      <feature.icon className="h-6 w-6 text-white dark:text-gray-300" aria-hidden="true"/>
                                    </div>
                                    { feature.name }
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600 dark:text-gray-400">{ feature.description }</dd>
                                </div>
                            ))}


                    </dl>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
    </div>
    </div>
    )
}
export default Services;
