import React from "react";
import grrt from "../assets/img/grrt.png";
import kord from "../assets/img/kord.svg";
import unistroy from "../assets/img/unistroy.png";
import akbdom from "../assets/img/akbdom.svg";
import fppi from "../assets/img/fppi.svg";
import volgaCapital from "../assets/img/volga-capital.svg";
import farolero from "../assets/img/farolero.jpeg";
import gnezdo from "../assets/img/gnezdo.svg";
const Partners = () => {
    return (
        <div className="bg-white py-8 sm:pt-32 sm:pb-24 dark:bg-gray-900" id="partners">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="mb-24 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-300">С
                    нами уже работают: </h2>
                <div
                    className="dark:bg-gray-300 dark:rounded-lg p-6 mx-auto mt-10 grid max-w-lg grid-cols-4 justify-center items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">

                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={grrt} alt="GRRT"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={kord}
                         alt="Kord"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={unistroy}
                         alt="Unistroy"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={akbdom}
                         alt="AkBarsDom"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={fppi}
                         alt="FPPI"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={volgaCapital} alt="VolgaCapital"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={farolero}
                         alt="Farolero"/>
                    <img className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                         src={gnezdo}
                         alt="Gnezdo"/>
                </div>
            </div>
        </div>
    );
}
export default Partners;
