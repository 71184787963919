import {useEffect, useState} from "react";
import Cookies from "js-cookie";

const CookieMessage = () => {

    const [cookieMessageShown, setCookieMessageShow] = useState('hidden');


    useEffect(() => {

        Cookies.get('cookieMessageShown') ? setCookieMessageShow(Cookies.get('cookieMessageShown')) : setCookieMessageShow('fixed');

    }, [cookieMessageShown]);


    const handleClick = () => {
        Cookies.set('cookieMessageShown', 'hidden');
        setCookieMessageShow('hidden');
    }

    return(

        <div  className={ cookieMessageShown + " z-50 max-w-screen-md ms-auto  bg-white dark:bg-gray-700 inset-x-5 p-5 bottom-10 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between " }>
            <div className="w-full text-gray-900 dark:text-gray-400">
                Пользуясь нашим сайтом, вы соглашаетесь с тем, что мы используем cookies.
            </div>
            <div className="flex gap-4 items-center flex-shrink-0">
                <button onClick={handleClick} className="bg-indigo-500 px-5 py-2 text-white rounded-md hover:bg-indigo-700 focus:outline-none">
                    Хорошо
                </button>
            </div>
        </div>

    );
}
export default CookieMessage
