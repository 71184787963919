import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import NoPage from "./pages/NoPage";
import ServicesPage from "./pages/ServicesPage";
import Layout from "./pages/Layout";
import PartnersPage from "./pages/PartnersPage";
import PricePage from "./pages/PricePage";


const App = () => {




  return (
      <BrowserRouter>
          <Routes >
              <Route path="/" element={<Layout/>}>
                  <Route index element={<Home />}/>
                  {/*<Route path="blog" element={<Blog />} />*/}

                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/partners" element={<PartnersPage />} />
                  <Route path="/price" element={<PricePage />} />
                  <Route path="*" element={<NoPage/>}/>
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
