import Footer from "../sections/Footer";
import Header from "../sections/Header";
import {Outlet} from "react-router-dom";
import Modal from "../components/Modal";
import {useState} from "react";
import FormPromo from "../components/FormPromo";
import Cookies from "js-cookie";
import CookieMessage from "../components/CoockieMessage";

const Layout = () => {

    const [isOpenModal2, setIsOpenModal2] = useState(false);

    const formPromo = <FormPromo />
    const formPromoShown = Cookies.get('formPromoShown');



    const handleMouseLeave = () =>{

        if (!formPromoShown){
            setIsOpenModal2(true);
            Cookies.set('formPromoShown', true, { expires: 7})
        }
    }
    return(
        <div id="app"  onMouseLeave={handleMouseLeave}>
            <Header/>
            <main className="mt-20">
                 <Outlet />
            </main>
            <Footer/>
            <Modal show={isOpenModal2} close={() => setIsOpenModal2(false)} modalContent={formPromo}/>
            <CookieMessage/>
        </div>
    )
}
export default Layout
