import {PaperAirplaneIcon} from "@heroicons/react/20/solid";
import InputMask from 'react-input-mask';
import {useState} from "react";
import axios from "axios";
const FormPromo = () =>{

    const [form, setForm] = useState({
        name: "",
        phone: ""
    });

    //const [nameField,setNameField]= useState('gray-300');
    const [nameValid,setNameValid]= useState(false);
    //const [phoneField,setPhoneField]= useState('gray-300');
    const [phoneValid,setPhoneValid]= useState(false);
    const [mailSend, setMailSend]= useState(false)

    const countDigitsInString = (str) => {
        return str.replace(/[^0-9]/g, '').length;
    }
    const verifyLength = (value, length) => {
        return value.length >= length;
    };



    const onUpdateField = (e) => {


        if(e.target.name === 'name'){


            if(verifyLength(e.target.value,2)){
                //setNameField('indigo-500');
                setNameValid(true);
            }
            else{

                //setNameField('red-600');
                setNameValid(false);
            }

        }

        if(e.target.name === 'phone'){
            if( countDigitsInString(e.target.value) < 11){

                //setPhoneField('red-600');
                setPhoneValid(false);

            }
            else{
                //setPhoneField('indigo-500');
                setPhoneValid(true);

            }
        }





        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);

    };
    const onSubmitForm = e => {
        e.preventDefault();

        // eslint-disable-next-line no-undef
        _tmr.push({ type: 'reachGoal', id: 3408718, goal: 'FORM_SENT'});

        const url=process.env.REACT_APP_API_URL + "send_to_telegram";


        const payload = {
            name: form.name,
            phone: form.phone,
        }

        axios.post(url, payload)
            .then((res) => {

                if(res.status === 200){
                    setMailSend(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return(
        <div>
        {
            mailSend ? <div className='flex justify-center items-center text-lg p-10 text-gray-900 dark:text-white'>
                            Спасибо, ваша заявка отправлена!
                        </div> :
                        <form onSubmit={onSubmitForm}>
                            <h3 className= "text-gray-900 dark:text-gray-300 my-5 sm:text-center font-bold tracking-tight text-3xl">
                                Бесплатный аудит вашего сайта
                            </h3>
                            <h5 className="text-gray-900 dark:text-gray-300 my-5 sm:text-center font-bold tracking-tight">
                                оставьте заявку и мы сразу перезвоним
                            </h5>
                            <PaperAirplaneIcon className="w-10 h-10 text-indigo-600 mx-auto mb-3"/>
                            <div className="mn-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label htmlFor="name"
                                           className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Ваше
                                        имя</label>
                                    <div className="mt-2">
                                        <input type="text"
                                               name="name"
                                               id="name"
                                               className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 ring-1 ring-gray-300 dark:ring-gray-600 focus:ring-indigo-600 dark:focus:ring-indigo-600 ring-inset outline-0 placeholder:text-gray-400 dark:placeholder:text-gray-400  sm:text-sm sm:leading-6 dark:bg-gray-700 dark:text-white"
                                               placeholder="Ваше имя"
                                               value={form.name}
                                               onChange={onUpdateField}

                                        />
                                    </div>

                                </div>
                                <div className="col-span-full">
                                    <label htmlFor="phone"
                                           className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Телефон</label>
                                    <div className="mt-2">

                                        <InputMask
                                            mask={'+7(999)999 99 99'}
                                            name="phone"
                                            id="phone"
                                            autoComplete="phone"
                                            className={"block w-full rounded-md border-0 py-2 px-3 text-gray-900 ring-1 ring-gray-300 dark:ring-gray-600 focus:ring-indigo-600 dark:focus:ring-indigo-600 ring-inset outline-0 placeholder:text-gray-400 dark:placeholder:text-gray-400  sm:text-sm sm:leading-6 dark:bg-gray-700 dark:text-white"}
                                            placeholder="Телефон"
                                            value={form.phone}
                                            onChange={onUpdateField}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="text-right mt-8">
                                <button

                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2
                                    text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto
                                    disabled:opacity-50 disabled:hover:opacity-50 disabled:cursor-not-allowed
                                    "
                                    disabled={nameValid && phoneValid ? false : 'disabled'}
                                >
                                    Отправить
                                </button>
                            </div>
                        </form>
        }
        </div>
    )
}
export default FormPromo
