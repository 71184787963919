import {useEffect, useState} from 'react'
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Switcher from "../components/Switcher";
import {useNavigate} from "react-router-dom";


const Header = () => {
    const navigate = useNavigate();
    //const location = useLocation();
    const  navigation =
                [
                    {name: 'Услуги', id: 'services'},
                    {name: 'Партнеры', id: 'partners'},
                    {name: 'Цены', id: 'price'},
                    {name: 'Контакты', id: 'contacts'}
                ]



    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const handleClickScroll = (section) => {
        const yOffset = -90;
        const element = document.getElementById(section);

        if (element) {

            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            setMobileMenuOpen(false);
        }
        else{
            navigate(section);
        }
    };


    return (

       <header className="inset-x-0 top-0 z-50 fixed backdrop-blur bg-white/10 dark:bg-gray-900/10">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="leading-none text-center">
                            <span className="font-bold text-2xl font-sans leading-none dark:text-gray-300">770Studio</span><br/>
                            <span className="text-xs text-gray-500 leading-none dark:text-gray-300">web agency</span>
                        </a>
                    </div>

                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <button key={item.name} onClick={()=>handleClickScroll(item.id)}
                               className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 dark:text-gray-300 dark:hover:text-white">
                                {item.name}
                            </button>
                        ))}
                        {/*<button*/}

                        {/*    onClick={()=>navigate('/blog')}*/}
                        {/*    className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 dark:text-gray-300 dark:hover:text-white">*/}
                        {/*    Блог*/}
                        {/*</button>*/}
                    </div>
                    <div className="flex items-center">
                        <div className="flex me-3 ms-5">
                            <Switcher />
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300 dark:hover:text-white"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50"/>
                    <Dialog.Panel
                        className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 dark:bg-gray-900">
                        <div className="flex items-center justify-between">
                            <a href="/" className="leading-none text-center">
                                <span
                                    className="font-bold text-2xl font-sans leading-none dark:text-gray-300">770Studio</span>
                                <br/>
                                    <span
                                        className="text-xs text-gray-500 leading-none dark:text-gray-300">web agency</span>
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:text-indigo-600 dark:text-gray-300 dark:hover:text-white"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <button
                                            key={item.name}
                                            onClick={()=>handleClickScroll(item.id)}
                                            className="-mx-3 w-full text-left block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-300 dark:hover:text-gray-900"
                                        >
                                            {item.name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

    );
}

export default Header;
