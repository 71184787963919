import Services from "../sections/Services";
import {PaperAirplaneIcon} from "@heroicons/react/20/solid";
import Modal from "../components/Modal";
import {useState} from "react";
import Form from "../components/Form";

const ServicesPage = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const formRequest = <Form />
    return(
        <>
        <Services/>
            <div className="mt-10 flex items-center justify-center gap-x-6 flex-col md:flex-row gap-y-6 z-10 relative">
                <button
                    onClick={()=>setIsOpenModal(true) }
                    className="leading-normal flex items-center justify-center gap-2 rounded-md  px-3.5 py-2.5
                                text-sm font-semibold text-white shadow-sm hover:bg-indigo-500
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                                focus-visible:outline-indigo-600  md:w-fit w-[calc(100%-3rem)]
                                bg-gradient-to-r from-indigo-500 to-indigo-600"
                >
                                <span>
                                    <PaperAirplaneIcon className="w-5 h-5 text-white"/>
                                </span>
                    Оставить заявку
                </button>


            </div>
            <Modal show={isOpenModal} close={() => setIsOpenModal(false)} modalContent={formRequest}/>
        </>
    )
}
export default ServicesPage
