import { Dialog, Transition } from '@headlessui/react';
import {Fragment,  useRef} from 'react';
import {XMarkIcon} from "@heroicons/react/24/outline";

const Modal = ({show,close,modalContent}) =>{
    const cancelButtonRef = useRef(null);

    return(

        <Transition.Root show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={close}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg dark:bg-gray-700" >
                                <button type="button" className="absolute top-0 right-0 rounded-md p-2.5 text-gray-700 hover:text-indigo-600 dark:text-gray-300 dark:hover:text-white focus:outline-0" onClick={close} >
                                   <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                    {modalContent}
                                </div>

                             </Dialog.Panel>
                        </Transition.Child>
                    </div>
</div>
            </Dialog>
        </Transition.Root>
    )

}
export default Modal
