import {PhoneIcon} from "@heroicons/react/20/solid";
import WAIcon from "../assets/img/wa.svg";
const Footer = () =>{

    const currentYear = new Date().getFullYear();
    const whatsappLink = "https://wa.me/" + process.env.REACT_APP_WHATSAPP_NUMBER;
    const phoneLink = "tel:" + process.env.REACT_APP_PHONE_NUMBER;
    return (

        <footer className="bg-transparent relative z-10 pt-5 border-t border-gray-200 dark:border-gray-700 mt-20" id="contacts">
            <div className="max-w-screen-xl px-4 pb-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
                <div className="flex flex-col justify-center mt-8 gap-3 items-center">
                    <a href={whatsappLink} className="text-gray-900 hover:text-gray-500 dark:text-gray-400 flex flex-row items-center gap-3" >
                        <img src={WAIcon} alt="Whatsapp" className="w-6 h-6"/>
                        <span>Whatsapp</span>
                        <span>+{process.env.REACT_APP_WHATSAPP_NUMBER}</span>
                    </a>
                    <a href={phoneLink} className="text-gray-900 hover:text-gray-500 dark:text-gray-400 flex flex-row items-center gap-3" >
                        <PhoneIcon className="w-6 h-6" />
                        <span>Телефон</span>
                        <span>{process.env.REACT_APP_PHONE_NUMBER}</span>
                    </a>
                </div>
                <p className="mt-8 text-xs leading-6 text-center text-gray-900 dark:text-gray-400">
                    © 2013-{currentYear} 770STUDIO, Inc. All rights reserved.
                </p>
            </div>
        </footer>
    )
}

export default Footer;
