import {PaperAirplaneIcon} from "@heroicons/react/20/solid";
import WAIcon from "../assets/img/wa.svg";
import {useState} from "react";
import Modal from "../components/Modal";
import Form from "../components/Form";

const Main = () =>{
    const whatsapp = "https://wa.me/" + process.env.REACT_APP_WHATSAPP_NUMBER;
    const WAGoal = () => {
        // eslint-disable-next-line no-undef
        _tmr.push({ type: 'reachGoal', id: 3408718, goal: 'WHATSAPP_CLICK'});
    }
    const [isOpenModal, setIsOpenModal] = useState(false);
    const formRequest = <Form />
    return(
        <>
            <div className="bg-white dark:bg-gray-900">
                <div className="relative isolate px-6 pt-0 lg:px-8">
                <div className="absolute inset-0 -z-10 overflow-hidden">
                    <svg
                        className="absolute
                        left-[max(50%,25rem)]
                        top-36
                        h-[64rem]
                        w-[128rem]
                        -translate-x-1/2
                        md:stroke-gray-200
                        md:dark:stroke-gray-800

                        [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                width={200}
                                height={200}
                                x="50%"
                                y="50%"
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M100 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                        </defs>

                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                    </svg>
                </div>
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"  aria-hidden="true">
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-white">
                            Доверьте нам заботу <br/>
                            о вашем веб-проекте!
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                            Поддержка и сопровождение сайтов от профессионалов
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6 flex-col md:flex-row gap-y-6">
                            <button
                                onClick={()=>setIsOpenModal(true)}
                                className="leading-normal flex items-center justify-center gap-2 rounded-md  px-3.5 py-2.5
                                text-sm font-semibold text-white shadow-sm  hover:bg-indigo-100
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                                focus-visible:outline-indigo-600  md:w-fit w-[calc(100%-3rem)]
                                bg-gradient-to-r from-indigo-500 to-indigo-600"
                            >
                                <span>
                                    <PaperAirplaneIcon className="w-5 h-5 text-white"/>
                                </span>
                                Оставить заявку
                            </button>

                            <a href={whatsapp} target="_blank"
                               className="bg-white leading-normal flex rounded-md gap-2
                               items-center px-3.5 py-2.5 border border-solid border-gray-300 justify-center
                               dark:border-gray-600 text-sm font-semibold  text-gray-900 hover:opacity-80
                               dark:bg-gray-800
                               dark:text-gray-300 dark:hover:text-white md:w-fit w-[calc(100%-3rem)]" rel="noreferrer"

                               onClick= { WAGoal }
                            >
                                 <span aria-hidden="true">
                                    <img src={WAIcon} alt="Whatsapp" className="w-5 h-5"/>
                                </span>
                                Написать в Whatsapp

                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
            </div>
             <Modal show={isOpenModal} close={() => setIsOpenModal(false)} modalContent={formRequest}/>
        </>
    );
}
export default Main
